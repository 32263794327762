<template>
    <el-dialog
        class="add-period"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        width="600px"
        :close-on-click-modal="false"
    >
        <el-form
            :model="form"
            ref="form"
            label-width="120px"
            :show-message="false"
        >
            <el-form-item label="客户" required prop="partnerId">
                <template v-if="$attrs.allPartners">
                    <el-select
                        v-model="form.partnerId"
                        filterable
                        placeholder="请选择"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in $attrs.allPartners"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-form-item>
            <el-form-item label="收款公司" required prop="companyId">
                <template v-if="$attrs.partners">
                    <el-select
                        v-model="form.companyId"
                        filterable
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in $attrs.partners.slice(1)"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-form-item>
            <el-form-item
                class="input-with-select"
                label="应收款"
                required
                prop="receivableAmount"
            >
                <el-select v-model="form.currency" placeholder="请选择">
                    <el-option value="RMB" label="RMB"></el-option>
                </el-select>
                <el-input
                    style="margin-left: 8px"
                    placeholder="请输入金额"
                    v-model="form.receivableAmount"
                    onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                    maxlength="11"
                >
                </el-input>
                <!-- <el-input-number
                    v-model="form.balanceAmount"
                    controls-position="right"
                    @change="handleChange"
                ></el-input-number> -->
            </el-form-item>
            <el-form-item label="业务时间" prop="dateRange">
                <el-date-picker
                    v-model="form.dateRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="预计收款时间" required prop="receiveTime">
                <el-date-picker
                    v-model="form.receiveTime"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="收款备注">
                <el-input
                    type="textarea"
                    v-model="form.remark"
                    rows="4"
                ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <template v-if="isEdit">
                <el-popover
                    style="margin-right: 10px"
                    placement="top"
                    width="160"
                    v-model="popVisible2"
                >
                    <p>确定收款吗？</p>
                    <div style="text-align: right; margin: 0">
                        <el-button
                            size="mini"
                            type="text"
                            @click="popVisible2 = false"
                            >取消</el-button
                        >
                        <el-button
                            type="primary"
                            size="mini"
                            @click="updateStatus"
                            >确定</el-button
                        >
                    </div>
                    <!-- <el-button slot="reference">删除</el-button> -->
                    <!-- <el-button slot="reference" class="del">删除</el-button> -->
                    <el-button slot="reference" type="primary">收款</el-button>
                </el-popover>
                <el-popover
                    style="margin-right: 10px"
                    placement="top"
                    width="160"
                    v-model="popVisible"
                >
                    <p>确定删除吗？</p>
                    <div style="text-align: right; margin: 0">
                        <el-button
                            size="mini"
                            type="text"
                            @click="popVisible = false"
                            >取消</el-button
                        >
                        <el-button
                            type="primary"
                            size="mini"
                            @click="delCheckReceiveBill"
                            >确定</el-button
                        >
                    </div>
                    <!-- <el-button slot="reference">删除</el-button> -->
                    <el-button slot="reference" class="del">删除</el-button>
                </el-popover>
            </template>
            <template v-else> </template>
            <el-button :type="isEdit ? null : 'primary'" @click="handleConfirm"
                >保存</el-button
            >
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import financeSvc from 'services/finance'
export default {
    name: 'AddPeriod',
    components: {},
    data() {
        return {
            visible: false,
            title: '核销应收创建',
            form: {
                currency: 'RMB',
                dateRange: []
            },
            isEdit: false,
            popVisible: false,
            popVisible2: false
        }
    },
    watch: {
        data(newValue) {
            if (newValue) {
                this.clearValidate()
            }
        }
    },
    computed: {
        // 业务开始时间
        startTime() {
            return this.$moment(this.form.dateRange[0]).format('YYYY-MM-DD')
        },
        // 业务截止时间
        endTime() {
            return this.$moment(this.form.dateRange[1]).format('YYYY-MM-DD')
        }
    },
    methods: {
        // 新增
        handleConfirm() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    let apiName = ''
                    const param = this.form
                    if (!this.isEdit) {
                        apiName = 'saveCheckReceiveBill'
                        param.startTime = this.startTime
                        param.endTime = this.endTime
                    } else {
                        apiName = 'updateCheckReceiveBill'
                    }
                    try {
                        await financeSvc[apiName](param)
                        this.$message.success('保存成功！')
                        this.handleCancel()
                        this.$listeners['handle-refresh']()
                    } catch (e) {
                        console.log(e)
                    }
                } else {
                    console.log('error submit')
                }
            })
        },
        // delete
        async delCheckReceiveBill() {
            await financeSvc.delCheckReceiveBill(this.form.id)
            this.popVisible = false
            this.$message.success('删除成功！')
            this.handleCancel()
            this.$listeners['handle-refresh']()
        },
        // updateStatus
        async updateStatus() {
            await financeSvc.updateStatus({
                id: this.form.id
            })
            this.popVisible2 = false
            this.$message.success('收款成功！')
            this.handleCancel()
            this.$listeners['handle-refresh']()
        },
        // query
        async queryCheckReceiveBillById(id) {
            try {
                await financeSvc.queryCheckReceiveBillById(id)
            } catch (error) {
                console.log(error)
            }
        },
        handleCancel() {
            this.form = {
                currency: 'RMB'
            }
            this.dateRange = []
            this.clearValidate()
            this.handleVisible(false)
        },
        handleVisible(boo) {
            this.visible = boo
            if (boo) {
                this.$nextTick(() => {
                    this.clearValidate()
                })
            }
        },
        clearValidate() {
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        resetForm() {
            this.$refs.form.resetFields()
        }
    }
}
</script>
<style lang="scss" scoped>
.add-period {
    ::v-deep .el-dialog__body {
        padding: 0;
        height: 490px;
        .el-form {
            padding: 30px;
        }
        .el-input-number,
        .el-date-editor,
        .el-input {
            width: 240px;
        }
        .el-textarea {
            width: 400px;
        }
        .input-with-select {
            .el-select {
                width: 100px;
                .el-input {
                    width: 100%;
                }
            }
            .el-input {
                width: 132px;
            }
        }
        .el-textarea__inner,
        .el-input__inner {
            border-radius: 0;
        }
    }
    ::v-deep .el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;
        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            border-radius: 8px;
            font-size: 14px;
        }
        .el-button.del {
            background-color: #df5a68;
            color: #fff;
        }
    }
}
</style>
<style>
